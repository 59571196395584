import * as React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  BreadCrumb,
  Container,
  Section,
  MarkdownContent,
  SectionPageTitle,
} from "../components/Section"
import {
  MainPanelContainer,
  MainPanel,
  LeftPanel,
  RightPanel,
  IsSticky,
  CardWhite,
  CardWhiteTitle,
  CardWhiteBody,
} from "../components/StaticTemplate"
import ExpertSection from "../components/Expert"
import FormSitePreparation from "../components/RequestForm/FormSitePreparation"

function ContentTemplate({ location, data }) {
  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title={data.resource.metaTitle}
        description={data.resource.metaDescription.metaDescription}
      />
      <Section
        xpt="170px"
        mpt="150px"
        pt="90px"
        xpb="90px"
        mpb="60px"
        pb="60px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <MainPanelContainer>
            <BreadCrumb
              textAlign="left"
              position="static"
              translate="translate(0)"
              justifyContent="left"
            >
              <Link to="/">Home</Link>
              <span>{data.resource.pageTitle}</span>
            </BreadCrumb>
            <SectionPageTitle textAlign="left">
              {data.resource.pageTitle}
            </SectionPageTitle>
            <MainPanel>
              <LeftPanel>
                <MarkdownContent>
                  <p>
                    <GatsbyImage
                      image={getImage(data.resource.heroImage)}
                      alt={data.resource.pageTitle}
                    />
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.resource.content.childMarkdownRemark.html,
                    }}
                  />
                </MarkdownContent>
              </LeftPanel>
              <RightPanel>
                <IsSticky>
                  <CardWhite className="card">
                    <CardWhiteBody className="card-body">
                      <CardWhiteTitle className="card-title">
                        QUICK CONTACT
                      </CardWhiteTitle>
                      <FormSitePreparation location={location} />
                    </CardWhiteBody>
                  </CardWhite>
                </IsSticky>
              </RightPanel>
            </MainPanel>
          </MainPanelContainer>
        </Container>
      </Section>
      <ExpertSection />
    </Layout>
  )
}
export default ContentTemplate

export const query = graphql`
  query ResourceTemplatePageQuery($id: String!) {
    resource: contentfulResourcePage(id: { eq: $id }) {
      id
      url
      metaTitle
      metaDescription {
        metaDescription
      }
      pageTitle
      heroImage {
        gatsbyImageData(quality: 100)
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
