import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import DefaultButton from "../Button/DefaultButton"
import StateSelect from "../StateSelect"

class RequestForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      source: "",
      page: "",
      form: "",
      name: "",
      email: "",
      phone_no: "",
      state: null,
      zip_code: "",
      message: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  handleChangeSelect(state) {
    this.setState({ state })
  }
  handleSubmit(event) {
    event.preventDefault()
    const { location } = this.props
    const data = {
      source: location.origin,
      page: location.href,
      form: event.target.id,
      full_name: this.state.name,
      email: this.state.email,
      mobile_no: this.state.phone_no,
      state: this.state.state.value,
      zip_code: this.state.zip_code,
      commnet: "Message: " + this.state.message,
      api_token: process.env.GATSBY_FORM_CRM_TOKEN,
    }

    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        this.setState({
          source: "",
          page: "",
          form: "",
          name: "",
          email: "",
          phone_no: "",
          state: "",
          zip_code: "",
          message: "",
        })
        navigate("/thank-you-for-quick-contact/")
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    return (
      <form
        id="requestformsite"
        onSubmit={this.handleSubmit}
        className="requestformsite"
      >
        <div className="form-group">
          <input
            type="text"
            id="name"
            className="form-control"
            placeholder="Name"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            id="email"
            className="form-control"
            placeholder="Email Address"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            id="phone_no"
            className="form-control"
            placeholder="Phone Number"
            onChange={this.handleChange}
            pattern="[0-9]{10}"
            required
          />
        </div>
        <div className="form-group form-group-select">
          <StateSelect
            id="state"
            value={this.state.state}
            onChange={this.handleChangeSelect}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            id="zip_code"
            className="form-control"
            placeholder="Zip Code"
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form-group form-group-textarea">
          <textarea
            type="text"
            id="message"
            className="form-control"
            placeholder="Type your message here…"
            onChange={this.handleChange}
            required
            title=" "
          />
        </div>
        <div className="form-action">
          <button type="submit" aria-label="button" className="btn-block-lg">
            <DefaultButton text="Submit" size="lg" />
          </button>
        </div>
      </form>
    )
  }
}

export default RequestForm
