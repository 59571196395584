import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'

export const MainPanelContainer = styled.div`
    max-width:1900px;
    width:100%;
    margin: 0 auto;
`
export const MainPanel = styled.div`
    display: flex;  
    flex-wrap: wrap;   
    margin:0 -15px;    
`
export const LeftPanel = styled.div`
    position: relative;
    width: 100%;
    padding:0 15px;
    ${BreakpointUp.lg`
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    `}
    ${BreakpointUp.xxl`
        flex: 0 0 70%;
        max-width: 70%;
    `}   
`
export const RightPanel = styled.div`
    position: relative;
    width: 100%;
    padding:0 15px;
    ${BreakpointUp.lg`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
    ${BreakpointUp.xxl`
        flex: 0 0 30%;
        max-width: 30%;
    `}
`
export const IsSticky = styled.div`
    ${BreakpointUp.md`	
      max-width: 100%;
      width: 100%;
      position: sticky;
      z-index: 1;
      top: 94px;
    `}
    ${BreakpointUp.xl`	
      top: 124px;
    `}
    ${BreakpointUp.xxl`	
      top: 144px;
    `}
`


export const CardWhite = styled.div`
    background-color:#fff;  
    box-shadow: 0 20px 50px rgba(0,0,0,0.3);
    margin-bottom:30px;
`
export const CardWhiteTitle = styled.strong`
    display: block;
    color: #000;
    text-align: center;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    margin-bottom:20px;
    ${BreakpointUp.md`	
        font-size: 28px;
        line-height: 36px;
    `}
    ${BreakpointUp.xxl`	
        font-size: 32px;
        line-height: 42px;
    `}
`
export const CardWhiteBody = styled.div`
    padding:20px 20px 40px 20px;
    ${BreakpointUp.sm`	
        padding:30px 30px 50px 30px;
    `} 
    ${BreakpointUp.xl`	
        padding:30px 50px 50px 50px;
    `} 
    form{
        max-width: 550px;
        margin: 0 auto;
        width: 100%;
    }
    .form-action{
        text-align: center;
        margin:30px 0 0;
        ${BreakpointUp.md`        
            position: absolute;
            left: 0;
            right: 0;            
            top: calc(100% - 22px);        
            margin: 0;
        `}
        ${BreakpointUp.lg`             
            top: calc(100% - 26px);
        `}
    }

`